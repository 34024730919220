import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import "../Enlarger.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const Work = () => {
  const [animations, setAnimations] = useState([]);

  useEffect(() => {
    const lottieURLs = [
      "https://lottie.host/95ec8d3b-229a-4737-900e-df0194f941c2/hX7swzIP7B.json", // Animation 3
      "https://lottie.host/934aadd7-e034-4abb-a20a-d9911455a3b3/F3i2jFmRb5.json", // Animation 1
      "https://lottie.host/9443396a-f4f5-483d-b514-f27bc34d62a7/toenIKqG3z.json", // Animation 2
    ];

    const fetchAnimations = async () => {
      const loadedAnimations = await Promise.all(
        lottieURLs.map(async (url) => {
          const response = await fetch(url);
          const animationData = await response.json();
          return animationData;
        })
      );
      setAnimations(loadedAnimations);
    };

    fetchAnimations();
  }, []);

  if (animations.length === 0) {
    return <div>Loading animations...</div>;
  }

  const cardData = [
    {
      title: "Web Solutions",
      description:
        "We provide comprehensive web solutions paired with innovative designs to resolve complex business challenges.",
      animationData: animations[2],
    },
    {
      title: "App Solutions",
      description:
        "Our app solutions help businesses streamline processes, improve productivity, and enhance user engagement.",
      animationData: animations[0],
    },
    {
      title: "AI/ML Solutions",
      description:
        "Leverage AI/ML to enhance data-driven decision-making and automate key business functions.",
      animationData: animations[1],
    },
    {
      title: "AI/ML Solutions",
      description:
        "Leverage AI/ML to enhance data-driven decision-making and automate key business functions.",
      animationData: animations[1],
    },
  ];

  return (
    <div>
      {/* Carousel for screens less than xl */}
      <div className="block xl:hidden">
        <Carousel
          className=" pb-20"
          infiniteLoop
          showThumbs={false}
          showStatus={false}
          dynamicHeight
        >
          {cardData.map((card, index) => (
            <div
              key={index}
              className="flex flex-col items-center h-72 bg-gradient-to-br from-[#0e4421] to-[#000000]  w-72 ml-10 rounded-lg"
            >
              <Lottie
                animationData={card.animationData}
                loop
                autoplay
                className="w-20 mb-4"
              />
              <h2 className="text-xl font-bold text-white">{card.title}</h2>
              <p className="text-sm text-white text-center">
                {card.description}
              </p>
              <p className="font-bold text-md text-white mt-2">
                View In Detail
              </p>
            </div>
          ))}
        </Carousel>
      </div>

      {/* General content for screens xl and above */}
      <div className="hidden xl:block">
        <Carousel className=" xl:hidden">
          <div className="rotate-90 w-96 h-96 ml-[64%] xl:flex-row">
            {cardData.map((card, index) => (
              <div key={index} className="cardbody">
                <div className="card h-52 bg-gradient-to-br from-[#0e4421] to-[#000000] text-white">
                  <div
                    className="panel-container"
                    style={{ transform: "rotate(270deg)" }}
                  >
                    <div className="absolute -top-36 -left-20 w-20">
                      <Lottie
                        animationData={card.animationData}
                        loop
                        autoplay
                      />
                    </div>
                    <p className="panel absolute -top-16 -left-20 xl:text-xl font-bold">
                      {card.title}
                    </p>
                    <p className="panel absolute -top-5 -left-20 w-52 text-justify text-sm">
                      {card.description}
                    </p>
                    <p className="panel absolute top-24 font-bold xl:text-md -left-20 w-52 text-justify text-sm">
                      View In Detail
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Carousel>
        <div className="rotate-90 w-96 h-96 ml-[64%] xl:flex-row">
          {cardData.map((card, index) => (
            <div key={index} className="cardbody">
              <div className="card h-52 bg-gradient-to-br from-[#0e4421] to-[#000000] text-white">
                <div
                  className="panel-container"
                  style={{ transform: "rotate(270deg)" }}
                >
                  <div className="absolute -top-36 -left-20 w-20">
                    <Lottie animationData={card.animationData} loop autoplay />
                  </div>
                  <p className="panel absolute -top-16 -left-20 xl:text-xl font-bold">
                    {card.title}
                  </p>
                  <p className="panel absolute -top-5 -left-20 w-52 text-justify text-sm">
                    {card.description}
                  </p>
                  <p className="panel absolute top-24 font-bold xl:text-md -left-20 w-52 text-justify text-sm">
                    View In Detail
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Work;
